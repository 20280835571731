import { createContext, useCallback, useContext, useMemo } from 'react';

import { useRouter } from 'next/router';

interface IUseRouterMiddlerwareContextData {
  navigateTo: (path: string) => void;
}

const useRouterMiddlewareContext = createContext<IUseRouterMiddlerwareContextData>(
  {} as IUseRouterMiddlerwareContextData,
);
const UseRouterMiddlewareProvider: React.FC<IFCHasChildren> = ({ children }) => {
  const router = useRouter();

  const navigateTo = useCallback(
    (path: string) => router.push(path, path, { scroll: true }),
    [router],
  );

  const contextValue = useMemo(() => ({ navigateTo }), [navigateTo]);

  return (
    <useRouterMiddlewareContext.Provider value={contextValue}>
      {children}
    </useRouterMiddlewareContext.Provider>
  );
};
const useRouterMiddleware = (): IUseRouterMiddlerwareContextData => {
  const context = useContext(useRouterMiddlewareContext);
  if (!context)
    throw new Error('useuseRouterMiddleware must be used within an UseRouterMiddlewareProvider');
  return context;
};
export { UseRouterMiddlewareProvider, useRouterMiddleware };
