const routes = {
  auth: {
    intro: '/',
    introSlides: '/intro',
    login: '/login',
    logout: '/logout',
  },
  dashboard: '/inicio',
  notifications: '/notificacoes',
  menu: {
    index: '/cardapio',
    item: {
      edit: (id: string) => `/cardapio/item/${id}/editar`,
      create: `/cardapio/item/criar`,
    },
  },
  order: {
    list: '/pedidos',
    new: '/pedido/novo?reset=true',
    edit: (id: string) => `/pedido/${id}/editar`,
    view: (id: string) => `/pedido/${id}`,
    print: (id: string) => `/pedido/${id}/imprimir`,
  },
  support: {
    index: '/reportar-problema',
  },
  access: {
    index: '/acessos',
    newCode: '/acessos/novo-codigo',
    vinculateCode: '/acessos/vincular-codigo',
  },
  financial: {
    index: '/financeiro',
    requestMoney: '/financeiro/solicitar-resgate',
    reversePurchase: (id: string) => `/financeiro/estornar-compra/${id}`,
    financialStatment: '/financeiro/extrato-financeiro',
    checkingAccount: '/financeiro/conta-corrente',
    termsAndFees: '/financeiro/prazos-e-taxas',
  },
  checkCardBalance: '/consultar-saldo',
  profile: {
    changePassword: '/perfil/alterar-senha',
  },
  public: {
    stores: '/lojas',
    storeMenu: (id: string) => `/lojas/${id}/cardapio`,
  },
};

export default routes;
