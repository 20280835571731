import { i18n } from 'next-i18next';

export const translateError = (errorCode: string): string => {
  switch (errorCode) {
    /* case 'Order.NotFound':
    case 'Order.NotAllowed':
    case 'Order.AlreadyExists':
    case 'Order.InvalidField':
    case 'Order.Unespecified':
    case 'Order.Inactive':
    
    case 'Wallet.NotFound':
    case 'Wallet.NotAllowed':
    case 'Wallet.AlreadyExists':
    case 'Wallet.InvalidField':
    case 'Wallet.Unespecified':
    case 'Wallet.Inactive': */

    case 'Wallet.InsufficientFunds':
      return String(i18n?.t('common:validations:errors:insufficient-funds'));

    case 'Wallet.InvalidWallet':
      return String(i18n?.t('common:validations:errors:invalid-wallet'));

    /* case 'ORDER.NULLABLE-ORDER':
      return String(i18n?.t('common:validations:errors:order-try-again'));

    case 'PRODUCT.NOT-FOUND':
      return String(i18n?.t('common:validations:errors:order-product-not-found'));

    case 'PRODUCT.NOT-ALLOWED':
      return String(i18n?.t('common:validations:errors:order-product-not-allowed'));

    case 'PRODUCT.INVALID':
      return String(i18n?.t('common:validations:errors:order-product-out-of-stock'));

    case 'PRODUCT.STATUS':
      return String(i18n?.t('common:validations:errors:order-product-not-active'));

    case 'WALLET.NOT-FOUND':
      return String(i18n?.t('common:validations:errors:card-not-found'));
      
    case 'WALLET.STATUS':
      return String(i18n?.t('common:validations:errors:card-inactive-or-blocked'))

    case 'CARD.WALLET-NOT-BOUND':
      return String(i18n?.t('common:validations:errors:card-not-bound'))

    */

    default:
      return String(i18n?.t('common:validations:errors:generic-error'));
  }
};

// NotFound, NotAllowed, AlreadyExists, InvalidField, Unespecified E Inactive
/**
  Situações não mapeadas:
  wallet-not-bound
  wallet-inactive-or-blocked
 */
