import { signOut as signOutNextAuth, getSession } from 'next-auth/react';
import { i18n } from 'next-i18next';

import { logError } from 'lib/errorLog';
import Store from 'lib/Store';
import { v4 as uuid } from 'uuid';

import config from 'config';

import axios from './index';
import { translateError } from './translateError';

export const mainAPIURL = process.env.NEXT_PUBLIC_API_URL;
export const internalAPIURL = `${process.env.NEXT_PUBLIC_SITE_URL}/api`;
export const zipPayPIURL = `${process.env.NEXT_PUBLIC_ZIP_PAY_URL}`;

const userStore = Store(`${config.localStorageKey}-user`);

let timeoutRetries = 0;
const maxTimeoutRetries = 5;

// * - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const defaultError = (): IAPIReturn<any> => ({
  error: 'server',
  errorMessage: i18n?.t('common:validations:errors:generic-error'),
  errorCode: '',
  request: null,
});

// * - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const doRequest = ({
  path,
  method,
  contentType = 'application/json; charset=UTF-8',
  params,
  token,
  locale = i18n?.language || 'pt-BR',
  companyId,
  placeId,
}: IDoRequestProps): Promise<any> => {
  if (token) axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  if (companyId) axios.defaults.headers.common['X-PersonAId'] = companyId;
  if (placeId) axios.defaults.headers.common['X-PlaceId'] = placeId;

  let paramsProps = params;

  if (contentType === 'application/x-www-form-urlencoded; charset=UTF-8') {
    paramsProps = Object.entries(params)
      .map(([key, val]: any) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`)
      .join('&');
  }

  return axios(path, {
    method,
    headers: {
      Accept: '*/*',
      'Content-Type': `${contentType}`,
      'Accept-Language': locale,
    },
    validateStatus: () => true,
    ...(method !== 'GET'
      ? {
          data: params ? paramsProps : undefined,
        }
      : {}),
  });
};

// * - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export async function callAPI(requestProps: ICallAPIProps): Promise<IAPIReturn> {
  const { callId, path, method, disableLog, getFullResponse } = requestProps;

  try {
    let response = await doRequest(requestProps);

    // Verifica se ainda está com token válido
    if (response.status === 401) {
      // Tem token na sessão?
      const session = await getSession();

      if (session?.user.accessToken) {
        response = await doRequest({
          ...requestProps,
          token: session.user.accessToken,
        });
      }

      if (response.status !== 401) {
        if (getFullResponse) {
          return {
            error: null,
            request: response.data,
          };
        }

        return {
          error: null,
          request: response.data.Data,
        };
      }

      // Desloga
      userStore.clearAll();
      signOutNextAuth();

      return {
        error: 'expired-token',
        errorCode: '401',
        request: null,
      };
    }

    if (response.status === 200) {
      timeoutRetries = 0;

      if (getFullResponse) {
        return {
          error: null,
          request: response.data,
        };
      }

      return {
        error: null,
        request: response.data.Data,
      };
    }

    // Se caiu aqui, algo deu errado, então faz log
    if (!disableLog) {
      logError({
        message: `Erro #${callId || uuid()} - ${process.env.NEXT_PUBLIC_SITE_URL}`,
        data: response,
        id: callId || uuid(),
        method,
        path,
      });
    }

    return {
      error: 'server',
      errorMessage: response?.data?.Error?.Description || '',
      errorCode: response?.data?.Error?.Code || '',
      request: response,
    };
  } catch (error: any) {
    // Tenta novamente em caso de timeout
    if (
      (error.code === 'ECONNABORTED' || error.code === 'ERR_NETWORK') &&
      timeoutRetries < maxTimeoutRetries
    ) {
      timeoutRetries += 1;
      return callAPI(requestProps);
    }

    timeoutRetries = 0;

    if (!disableLog) {
      logError({
        message: `Erro #${callId || uuid()} - ${process.env.NEXT_PUBLIC_SITE_URL}`,
        data: error,
        id: callId || uuid(),
        method,
        path,
      });
    }

    return {
      error: 'server',
      request: null,
    };
  }
}

// * - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// Função criada para tratar requisições na api do ZipPay

export async function callZipPayAPI(requestProps: ICallZipPayAPI): Promise<IAPIReturn<any>> {
  const { callId, path, method, disableLog, getFullResponse, storeToken } = requestProps;

  const session = await getSession();
  const userToken = session?.user.accessToken;

  // Se não tem token nesse momento, então está deslogado
  if (!userToken) {
    userStore.clearAll();
    signOutNextAuth();

    return {
      error: 'expired-token',
      errorCode: '401',
      request: null,
    };
  }

  if (!storeToken) {
    return {
      error: 'expired-token',
      errorCode: '401',
      request: null,
    };
  }

  try {
    const response = await doRequest({ ...requestProps, token: storeToken });

    if (response.status === 401) {
      return {
        error: 'expired-token',
        errorCode: '401',
        request: null,
      };
    }

    if (response.status === 200) {
      timeoutRetries = 0;

      if (getFullResponse) {
        return {
          error: null,
          request: response.data,
        };
      }

      return {
        error: null,
        request: response.data,
      };
    }

    // Se caiu aqui, algo deu errado, então faz log
    if (!disableLog) {
      logError({
        message: `Erro #${callId || ''} - ${process.env.NEXT_PUBLIC_SITE_URL}`,
        data: response,
        id: callId || '',
        method,
        path,
      });
    }

    return {
      error: 'server',
      errorMessage: translateError(response?.data?.code),
      errorCode: response?.data?.code || '',
      request: response,
    };
  } catch (error: any) {
    // Tenta novamente em caso de timeout
    if (
      (error.code === 'ECONNABORTED' || error.code === 'ERR_NETWORK') &&
      timeoutRetries < maxTimeoutRetries
    ) {
      timeoutRetries += 1;
      return callZipPayAPI(requestProps);
    }

    timeoutRetries = 0;

    if (!disableLog) {
      logError({
        message: `Erro #${callId || ''} - ${process.env.NEXT_PUBLIC_SITE_URL}`,
        data: error,
        id: callId || '',
        method,
        path,
      });
    }

    return {
      error: 'server',
      request: null,
    };
  }
}
