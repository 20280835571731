import * as Sentry from '@sentry/react';

export const logError = (error: IErrorLog): void => {
  console.log(error);

  if (!process.env.NEXT_PUBLIC_SENTRY_ENABLED) return;

  Sentry.withScope((scope) => {
    scope.setTransactionName(error.message);
    scope.setTag('ambiente', process.env.NEXT_PUBLIC_SITE_URL);

    if (error.id && error.method && error.path)
      scope.setFingerprint([error.method, error.path, String(error.id)]);

    try {
      Sentry.captureException({
        message: error.message,
        data: encodeURIComponent(JSON.stringify(error.data)),
      });
    } catch (_) {
      Sentry.captureException({
        message: `${error.message} [não foi possível fazer stringfy das informações de erro]`,
        data: encodeURIComponent(error.data),
      });
    }
  });
};
